import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Image, Card } from 'react-bootstrap';
import { Button, Modal, message } from 'antd';
import Cookies from 'js-cookie';
import neldLogo from '../images/SRHR.png'
import neldAgenda from '../images/neldAgenda2.jpg'
import Bwongo2 from '../images/bwongoAgenda1.png'
import Bwongo1 from '../images/bwongoAgenda2.png'
import Loader from '../Loader';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import pinnitags from '../images/plogotransparent.png'
import { fetchData } from '../../Utilities/apiRequests';
import { pinnisoftUrl, pinnitagsCookiePolicy, pinnitagsLandingUrl, pinnketBaseUrl } from '../../Utilities/globals';
import * as RxIcon from 'react-icons/rx';
import * as GiIcon from 'react-icons/gi';
import * as MdIcon from 'react-icons/md';
import { convertFileToDataURL, downloadContactCSV, getNairobiTime, getformattedDate2 } from '../../Utilities/utilities';
import PhoneInput from 'react-phone-input-2';
import successGif from '../images/success.gif';
import successImage from '../images/success.jpg';
import EventPass from '../PassTemplate';
import { useRef } from 'react';
import { BiDonateHeart } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';



const TicketPassVerification = () => {

    const [loading, setLoading] = useState(false);
    const [tagDetails, setTagDetails] = useState('');
    const [selfReg, setSelfReg] = useState(null);
    const [donation, setDonation] = useState(null);
    const [listImage, setListImage] = useState(null);
    const [eventDetails, setEventDetails] = useState('');
    const [sponsors, setSponsors] = useState([]);
    const [cookypolicy, setCookypolicy] = useState(true);
    const [userSelectedFields, setUserSelectedFields] = useState([])
    const [faceImage, setFaceImage] = useState('');
    const [showAgenda, setShowAgenda] = useState(false);
    const [formData, setFormData] = useState({
        Gender: '',
        OfficialIDNo: '',
        Age: '',
        MaritalStatus: '',
        Profession: '',
        Company: '',
        Address: '',
        Role: '',
        Name: '',
        Email: '',
        Mobile: ''
    });


    const clearForm = () => {
        setFormData({
            Gender: '',
            OfficialIDNo: '',
            Age: '',
            MaritalStatus: '',
            Profession: '',
            Company: '',
            Address: '',
            Role: '',
            Name: '',
            Email: '',
            Mobile: ''
        })
        setFaceImage('')
    }


    const { tag, tagType } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const registerFormRef = useRef(null);



    useEffect(() => {
        if ((!tagType) || (!tag)) {
            window.location.href = pinnitagsLandingUrl
        }
    }, [])



    useEffect(() => {

        fetchData(`/api/events/registration/tagdetails/${tagType}/${tag}`, setLoading).then((response) => {


            if (response.status == 200) {

                setTagDetails(response.data.tagDetails)
                setEventDetails(response.data.eventDetails)
                setSponsors(response.data?.sponsors?.filter((data) => {
                    return data.active
                }))

            }

        }).catch((error) => {

            if (error.response?.status == 403 || error.response?.status == 401 || error.response?.status == 400) {
                window.location.href = pinnitagsLandingUrl
            }

            console.log('Data loading error:', error)

        })

    }, [])


    useEffect(() => {
        if (eventDetails) {
            if (eventDetails?.endtime < getNairobiTime()) {
                twit('info', 'Event is Expired...', 4)
                window.location.href = pinnitagsLandingUrl
            }
            const userfields = eventDetails?.regStruct?.split(',')
            const showListingImage = eventDetails?.listingImageOnTicket
            const showRegistration = eventDetails?.selfReg
            const showDonation = (eventDetails?.acceptDonations && eventDetails?.donationOnScan)
            setUserSelectedFields(userfields)
            setSelfReg(showRegistration)
            setListImage(showListingImage)
            setDonation(showDonation)
        }
    }, [eventDetails])

    useEffect(() => {
        if (eventDetails) {
            if ((!selfReg) && (!listImage) && (!donation)) {
                twit('info', 'Self Registration, Listing Image and Donation were disabled for the event.', 4)
                window.location.href = pinnitagsLandingUrl
            } else if ((!selfReg) && (!listImage) && donation) {
                window.location.href = `${pinnketBaseUrl}/#/events/${eventDetails.eid}`
            }

        }
    }, [selfReg, listImage, donation])

    const faceformData = new FormData()
    faceformData.append("image", faceImage);


    const registerUser = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const faceImageData = await convertFileToDataURL(faceImage)
        let postData = {
            tag,
            tagType,
            Gender: formData.Gender,
            OfficialIDNo: formData.OfficialIDNo,
            Age: formData.Age,
            Visual: faceImageData,
            MaritalStatus: formData.MaritalStatus,
            Profession: formData.Profession,
            Company: formData.Company,
            Address: formData.Address,
            Role: formData.Role,
            Name: formData.Name,
            Email: formData.Email,
            Mobile: formData.Mobile
        };


        const Url = '/api/events/registration/create'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Registered successfully.')
            }


        } catch (error) {
            setLoading(false);
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured during registration.')

        }
    };


    const EventCookiePolicy = Cookies.get('EventCookyPolicy');

    useEffect(() => {
        if (EventCookiePolicy) {
            setCookypolicy(false);
        }

    }, [cookypolicy, loading])




    const handleFormSubmit = (e) => {
        e.preventDefault();

        registerUser(setLoading).then((message) => {
            clearForm()
            twit('success', message, 4)
            window.location.href = pinnitagsLandingUrl
        }).catch((error) => {
            twit('info', error, 4)
            console.log('Registration error:', error)
        })


    };



    if (loading) {
        return (<Loader />);
    } else {

        return (
            <div className='event-Registration-container' style={{ fontSize: '12px' }}>
                {cookypolicy && <Modal open={true}

                    closable={false}
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                    footer={[


                    ]}
                >

                    <Col  >
                        <Row>
                            <RxIcon.RxCookie style={{ width: '150px', height: '150px', color: "green" }} />
                        </Row>
                        <Row>
                            <h6>Your privacy</h6>
                            <p>By clicking "Accept all cookies",
                                you agree <span style={{ fontWeight: "bold" }}><a style={{ color: "green" }} href='https://pinnisoft.com/'>PinniSOFT</a> </span>can store cookies on your device
                                and disclose information in accordance with our
                                &nbsp;
                                <a style={{ color: "green" }} href={pinnitagsCookiePolicy}>Cookie Policy</a>

                            </p>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={() => {
                                Cookies.set('EventCookyPolicy', true, { expires: 7 });
                                setCookypolicy(false);
                            }} style={{ fontSize: '10px' }} variant="primary" htmlType='submit'>
                                <MdIcon.MdCookie className='mx-1' style={{ width: '15px', height: '15px' }} /> | Accept all Cookies
                            </Button>

                        </Row>

                    </Col>




                </Modal>}
                {showAgenda && <Modal open={true} title={'Event Agenda'} onCancel={() => {

                    setShowAgenda(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <Col  >
                        <Image
                            src={eventDetails?.agendaURL}
                            alt="agenda image"
                            fluid
                            style={{ border: '1px solid #ddd', borderRadius: '1%', width: '95%' }}
                            className='neldAgenda'
                        />

                    </Col>




                </Modal>}
                <Row>
                    <Col>

                        <div className='event-Registration-body'>
                            {(selfReg && listImage) ? <div className='event-Registration-overlaybutton-container' >
                                <Button className='event-Registration-overlaybutton' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='button' onClick={() => {
                                    registerFormRef.current.scrollIntoView({ behavior: "smooth" });
                                }} >
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> |  Register
                                </Button>
                            </div> : ''}

                            <div className='my-0 mx-0' style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img className='reg-banner-image' src={listImage ? eventDetails.evLogo : eventDetails.bannerURL} style={{ width: '100%' }} />
                            </div>

                            <div ref={registerFormRef} className='event-Registration-details-container'>

                                <div className='event-Registration-details-body' >
                                    <h1 style={{ margin: '1px', fontWeight: 'bold' }}>{eventDetails?.name}</h1>
                                    <p style={{ margin: '1px' }}>{eventDetails?.eventdescription}</p>
                                    <p style={{ margin: '1px' }}>
                                        {getformattedDate2(eventDetails?.eventDate)} -
                                    </p>
                                    <p style={{ margin: '1px' }}>
                                        {getformattedDate2(eventDetails?.endtime)}
                                    </p>
                                    <p style={{ margin: '1px' }}>
                                        {eventDetails?.venue}, {eventDetails?.location}
                                    </p>
                                </div>
                            </div>

                            {donation ?

                                <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                    <Button style={{ backgroundColor: "#FFFFFF", color: 'black', width: '50%' }} variant="primary" type="primary" onClick={() => { window.location.href = `${pinnketBaseUrl}/#/events/${eventDetails.eid}` }} >
                                        <BiDonateHeart className='mx-1' style={{ width: '15px', height: '15px' }} /> |  Donate
                                    </Button>
                                </div>

                                :

                                ''

                            }

                            {eventDetails?.agendaURL ?

                                <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                    <Button style={{ backgroundColor: "#FFFFFF", color: 'black', width: '50%' }} variant="primary" type="primary" onClick={() => { setShowAgenda(true) }} >
                                        <BsEye className='mx-1' style={{ width: '15px', height: '15px' }} /> |  View Agenda
                                    </Button>
                                </div>

                                :

                                ''

                            }

                            {selfReg ? <Form className='event-Registration-form' onSubmit={handleFormSubmit}>

                                <Row>

                                    <Col className='my-1' md={4}>
                                        <Form.Group controlId="name">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Name</p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="text"
                                                    placeholder="Enter name"
                                                    value={formData.Name}
                                                    onChange={(e) => {
                                                        setFormData((prev) => (
                                                            {
                                                                ...prev,
                                                                Name: e.target.value
                                                            }
                                                        ));
                                                    }}
                                                    required
                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    <Col className='my-1' md={4}>
                                        <Form.Group controlId="email">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Email</p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="email"
                                                    placeholder="Enter email"
                                                    value={formData.Email}
                                                    onChange={(e) => {
                                                        setFormData((prev) => (
                                                            {
                                                                ...prev,
                                                                Email: e.target.value
                                                            }
                                                        ));
                                                    }}
                                                    required
                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    <Col className='my-1' md={4}>

                                        <Form.Group controlId="Mobile">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Mobile</p>
                                                </div>

                                                <PhoneInput
                                                    country={'ug'}
                                                    value={formData.Mobile}
                                                    localization={{ ug: 'Uganda' }}
                                                    onChange={(e) => {

                                                        setFormData((prev) => (
                                                            {
                                                                ...prev,
                                                                Mobile: e
                                                            }
                                                        ));
                                                    }}


                                                    disableDropdown
                                                    countryCodeEditable={false}
                                                    masks={{ ug: '... ... ...' }}

                                                    inputProps={{
                                                        name: 'phone',
                                                        required: true,

                                                    }}


                                                    inputStyle={{
                                                        width: "100%",
                                                        height: "30px",
                                                        fontSize: "12px"
                                                    }}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    {/* {userSelectedFields?.includes('Visual') && (
        <Col className='my-1' md={4}>
            <Form.Group className='my-2' controlId="faceImage">
                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                    <div className='mx-2 FormLabels'>
                        <p style={{ margin: 0 }}>Visual</p>
                    </div>
                    <Form.Control

                        accept='.jpeg,.jpg,.png'
                        name="faceImage"
                        onChange={(e) => {

                            setFaceImage(e.target.files[0]);

                        }}
                        className=' input-box-events form-control-sm'

                        type="file" />
                </div>

            </Form.Group>

        </Col>
    )} */}

                                    {userSelectedFields?.includes('Address') && (
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="Address">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Address</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter Address"
                                                        value={formData.Address}
                                                        onChange={(e) => {
                                                            setFormData((prev) => (
                                                                {
                                                                    ...prev,
                                                                    Address: e.target.value
                                                                }
                                                            ));
                                                        }}
                                                        required
                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                    )}

                                    {userSelectedFields?.includes('Role') && (
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="Role">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Role</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter Role"
                                                        value={formData.Role}
                                                        onChange={(e) => {
                                                            setFormData((prev) => (
                                                                {
                                                                    ...prev,
                                                                    Role: e.target.value
                                                                }
                                                            ));
                                                        }}
                                                        required
                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                    )}
                                    {userSelectedFields?.includes('Gender') && (
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="Gender">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Gender</p>
                                                    </div>

                                                    <Form.Select

                                                        name="gender"
                                                        onChange={(e) => {
                                                            setFormData((prev) => (
                                                                {
                                                                    ...prev,
                                                                    Gender: e.target.value
                                                                }
                                                            ));
                                                        }}
                                                        className='form-selector'>
                                                        <option className='input-box' key={1} value={''}>Select Gender</option>
                                                        <option className='input-box' key={2} value={'Male'}>Male</option>
                                                        <option className='input-box' key={3} value={'Female'}>Female</option>

                                                    </Form.Select>


                                                </div>

                                            </Form.Group>
                                        </Col>
                                    )}
                                    {userSelectedFields?.includes('Age') && (
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="Age">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Age</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="number"
                                                        placeholder="Enter Age"
                                                        value={formData.Age}
                                                        onChange={(e) => {
                                                            setFormData((prev) => (
                                                                {
                                                                    ...prev,
                                                                    Age: e.target.value
                                                                }
                                                            ));
                                                        }}
                                                        required
                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                    )}
                                    {userSelectedFields?.includes('Company') && (
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="Company">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Company</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter Company"
                                                        value={formData.Company}
                                                        onChange={(e) => {
                                                            setFormData((prev) => (
                                                                {
                                                                    ...prev,
                                                                    Company: e.target.value
                                                                }
                                                            ));
                                                        }}
                                                        required
                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                    )}
                                    {userSelectedFields?.includes('Marital Status') && (
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="Marital Status">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Marital Status</p>
                                                    </div>

                                                    <Form.Select

                                                        name="maritalStatus"
                                                        onChange={(e) => {
                                                            setFormData((prev) => (
                                                                {
                                                                    ...prev,
                                                                    MaritalStatus: e.target.value
                                                                }
                                                            ));
                                                        }}
                                                        className='form-selector'>
                                                        <option className='input-box' key={1} value={''}>Select Marital Status</option>
                                                        <option className='input-box' key={2} value={'Married'}>Married</option>
                                                        <option className='input-box' key={3} value={'Single'}>Single</option>

                                                    </Form.Select>


                                                </div>

                                            </Form.Group>
                                        </Col>
                                    )}
                                    {userSelectedFields?.includes('Official ID No.') && (
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="idNo.">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Official ID No.</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter Official ID No."
                                                        value={formData.OfficialIDNo}
                                                        onChange={(e) => {
                                                            setFormData((prev) => (
                                                                {
                                                                    ...prev,
                                                                    OfficialIDNo: e.target.value
                                                                }
                                                            ));
                                                        }}
                                                        required
                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                    )}

                                    {userSelectedFields?.includes('Profession') && (
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="profession">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Profession</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter Profession."
                                                        value={formData.Profession}
                                                        onChange={(e) => {
                                                            setFormData((prev) => (
                                                                {
                                                                    ...prev,
                                                                    Profession: e.target.value
                                                                }
                                                            ));
                                                        }}
                                                        required
                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                    )}



                                </Row>




                                <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                    <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                        <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> |  Register
                                    </Button>
                                </div>


                            </Form> : ''}
                            <div className='event-Registration-sponsor-container' id="sp">
                                {
                                    sponsors?.map((sp, index) => (
                                        <div className='event-Registration-sponsor-wrapper' key={index}>
                                            <img className='event-Registration-sponsor-image' src={sp.url} alt='sp' />
                                        </div>
                                    ))}
                            </div>

                            <div className=" event-Registration-poweredby">
                                <p style={{ margin: '0%', fontWeight: 'bold', fontSize: '10px' }}>Powered By</p>
                                <img style={{ width: '15%' }} src={pinnitags} alt="Powered By Logo" />
                            </div>
                            <div className='event-Registration-footer' >
                                <div className=" event-Registration-footer-details">
                                    <p style={{ margin: '0px' }}>
                                        Copright &copy; {getNairobiTime().slice(0, 4)} PinniTAGS is a product of <a href={pinnisoftUrl} className="link">PinniSOFT</a>  <br />
                                        All rights reserved.
                                    </p>
                                </div>

                            </div>

                        </div>




                    </Col>
                </Row>
            </div>
        );
    };
};

export default TicketPassVerification;
