import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Table, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { Button, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { convertFileToDataURL, downloadImage, downloadPDF, getNairobiTime, getformattedDate, removeSpacesInString, simulateLoading, splitArrayIntoChunks, threeDigits } from '../../Utilities/utilities';
import { fetchData } from '../../Utilities/apiRequests';
import * as MdIcons from 'react-icons/md';
import * as AiIcons from 'react-icons/ai';
import * as TiIcons from 'react-icons/ti';
import * as HiIcons from 'react-icons/hi';
import * as GiIcon from 'react-icons/gi';
import * as GrIcon from 'react-icons/gr';
import * as BsIcons from 'react-icons/bs';
import { FaFileImport } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import visualPlacer from '../images/imagePlaceholder.png'
import { ReactMultiEmail } from 'react-multi-email';
import io from 'socket.io-client';
import { apiBaseUrl, fileUploadBaseUrl } from '../../Utilities/globals';
import PassPage from '../PassA4';
import ColorCube from '../ColorCube';
import ImagePreview from '../ImagePreview';
import ImageCropper from '../ImageCropper';
import Pagination from '../Pagination';
import UserSelectionComponent from '../EventPassSearchComponent';

const PassCategoryForm = () => {

    const [name, setName] = useState('');
    const [hname, setHname] = useState('');
    const [includedZones, setIncludedZones] = useState([]);
    const [zones, setZones] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [eventCategories, setEventCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedPasses, setSelectedPasses] = useState([]);
    const [eventPasses, setEventPasses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPasses, setShowPasses] = useState(false);
    const [updatePass, setUpdatePass] = useState(false);
    const [faceImage, setFaceImage] = useState(false);
    const [categoryRowData, setCategoryRowData] = useState();
    const [humanRowData, setHumanRowData] = useState();
    const [updatePassInfo, setUpdatePassInfo] = useState();
    const [selectedZones, setSelectedZones] = useState([]);
    const [selectedRzones, setSelectedRzones] = useState([]);
    const [eventInfo, setEventInfo] = useState();
    const [imageLoaded, setImageLoaded] = useState(true);
    const [admits, setAdmits] = useState(1);
    const [showEmailsInput, setShowEmailsInput] = useState(false);
    const [emails, setEmails] = useState([]);
    const [pctid, setPctid] = useState('');
    const [sendingMails, setSendingMails] = useState(false);
    const [numberSent, setNumberSent] = useState(0);
    const [socket, setSocket] = useState(null);
    const [focused, setFocused] = useState(null);
    const [pcolor, setPcolor] = useState('black');
    const [showPassDownload, setShowPassDownload] = useState(false);
    const [email, setEmail] = useState('');
    const [sendPassOnMail, setSendPassOnMail] = useState(false);
    const [showCropper, setShowCropper] = useState(false);
    const [cropperImage, setCropperImage] = useState('');
    const [currentPageH, setCurrentPageH] = useState(1);
    const [currentPageC, setCurrentPageC] = useState(1);
    const [numberToSend, setNumberToSend] = useState(0);
    const [passTitle, setPassTitle] = useState('');
    const [profession, setProfession] = useState('');
    const [initialTitle, setInitialTitle] = useState('');
    const [pTitles, setPTitles] = useState([]);
    const [currentPagePassPdf, setCurrentPagePassPdf] = useState(1);
    const [otherTitle, setOtherTitle] = useState('');
    const [sponsors, setSponsors] = useState([]);
    const [loadingSponsors, setLoadingSponsors] = useState(false);
    const [imageOnly, setImageOnly] = useState(false);
    const [activeOnly, setActiveOnly] = useState(false);
    const [inactiveOnly, setInactiveOnly] = useState(false);
    const [passDownloadData, setPassDownloadData] = useState([]);
    const [loadingSim, setLoadingSim] = useState(false);
    const [ptemplate, setPtemplate] = useState('');
    const [showPassImport, setShowPassImport] = useState(false);
    const [passIdentities, setPassIdentities] = useState([]);
    const [passIndentityIds, setPassIndentityIds] = useState([]);
    const [identityShareOnMail, setIdentityShareOnMail] = useState(false);
    const [description, setDescription] = useState('');
    const [searchTerm, setSearchTerm] = useState('');


    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };



    const { eid } = useParams();


    const faceformData = new FormData()
    faceformData.append("image", faceImage);



    const uploadFace = async (passid) => {
        const token = sessionStorage.getItem('token');

        try {



            const response = await axios.post(`${fileUploadBaseUrl}/api/events/face/${passid}`, faceformData, {
                headers: {
                    'content-type': 'multipart/form-data'
                    , Authorization: `Bearer ${token}`
                }

            });
            return Promise.resolve(response);
        } catch (error) {

            return Promise.reject(error);
        }



    }



    useEffect(() => {

        fetchData(`/api/events/zones/${eid}`, setLoading).then((response) => {

            setZones(response.data.zones)

        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event zones.')
        })

        fetchData(`/api/events/passcategory/${eid}`, setLoading).then((response) => {

            setEventCategories(response.data.categories)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event pass categories.')
        })

        fetchData(`/api/events/passes/${eid}`, setLoading).then((response) => {
            setEventPasses(response.data.passes)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event passes.')
        })


        fetchData(`/api/events/passidentities/all`, setLoading).then((response) => {
            setPassIdentities(response.data.identities)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event passes.')
        })



        fetchData(`/api/events/details/${eid}`, setLoading).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })

        fetchData(`/api/events/passtitles`, setLoading).then((response) => {
            setPTitles(response.data.titles)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })

    }, [refresh])


    useEffect(() => {

        const newSocket = io(apiBaseUrl, { transports: ['websocket', 'polling'] });
        setSocket(newSocket);

    }, []);


    useEffect(() => {

        if (eventInfo) {
            const sponsorData = eventInfo?.EventSponsors ? eventInfo.EventSponsors?.filter((data) => {
                return data?.active
            }) : []

            if (!((sponsorData?.length > 0) && (sponsorData?.length == sponsors?.length))) {

                for (const sp of sponsorData) {
                    if (sp?.url) {
                        setLoadingSponsors(true)
                        downloadImage(sp?.url).then((data) => {
                            setLoadingSponsors(false)
                            setSponsors((prev) => { if (!sponsors.includes({ src: data })) { return [...prev, { src: data }] } else { return sponsors } })
                        }).catch((error) => {
                            setLoadingSponsors(false)
                            console.log('Pass form image load error:', error)
                        })
                    }
                }
            }

        }

    }, [eventInfo]);


    if (socket) {


        socket.on('PassSent', (data) => {



            if (numberToSend = 0) {
                setNumberToSend(data.total)
            }

            setNumberSent(data.sent)

        });


    }

    const getCurrentPassTitle = () => {
        const title = pTitles?.filter((data) => {
            return data.id == passTitle
        })[0].name

        return title
    }



    const createEventPassCategory = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name,
            zones: includedZones,
            eid,
            pcolor,
            passtemplate: ptemplate,
            description
        };

        const Url = '/api/events/passcategory/create'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Category added successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log("Event Category  Error:", err.response.data.message)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while creating event category.')

        }
    };

    const updateEventPassCategory = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name: name ? name : categoryRowData.name,
            zones: selectedZones,
            rzones: selectedRzones,
            eid,
            pcid: categoryRowData.id,
            pcolor,
            passtemplate: ptemplate,
            description
        };

        const Url = '/api/events/passcategory/update'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Category updated successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log("Event Category update  Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while update event category.')

        }
    };


    const createPassHuman = async (setLoading) => {
        const token = sessionStorage.getItem('token');

        const faceImageData = await convertFileToDataURL(faceImage)



        const postData = {
            name: hname,
            pcid: selectedCategory.id,
            eid,
            email,
            sendPass: sendPassOnMail,
            imageData: faceImageData,
            profession,
            title: passTitle ? passTitle : null,
            otherTitle,
        };

        const Url = '/api/events/pass/new'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Pass created successfully.')

            }


        } catch (err) {
            setLoading(false);
            console.log("Event Pass  Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while creating event pass.')

        }
    };

    const updatePassHuman = async (setLoading) => {
        const token = sessionStorage.getItem('token');

        const faceImageData = await convertFileToDataURL(faceImage)

        const postData = {
            name: hname ? hname : humanRowData.name,
            email: email ? email : humanRowData.email,
            hpid: humanRowData.id,
            eid,
            imageData: faceImageData,
            profession,
            title: passTitle ? passTitle : null,
            otherTitle
        };

        const Url = '/api/events/pass/new/update'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Pass updated successfully.')



            }


        } catch (err) {
            setLoading(false);
            console.log("Event Pass  Error:", err.response?.message)
            return Promise.reject(err.response?.data?.message ? err.response?.data?.message : 'An error occured while updating event pass.')

        }
    };

    const revokePass = async (setLoading, passid) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            passid
        };

        const Url = '/api/events/passes/disable'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Pass status updated successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log("Pass Checker revoke Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while revoking the pass checker.')

        }
    };


    const getPassCategeoryRights = (data) => {
        const passZones = data?.PassCategoryZones?.filter((pcd) => {
            return pcd.pstate
        }).map((pcd) => {
            return pcd.EventZone.name + ' '
        })

        return passZones
    }



    const revokeActivateCategory = async (setLoading, pcid) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            pcid
        };

        const Url = '/api/events/passcategory/status/update'
        try {
            setLoading(true);
            setSendingMails(true)
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                setSendingMails(false)
                return Promise.resolve('Pass category status updated successfully.')
            }


        } catch (err) {
            setLoading(false);
            setSendingMails(false);
            console.log("Pass category update Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while updating pass category status.')

        }
    };


    useEffect(() => {
        if (selectedCategory) {
            const passes = eventPasses.filter((data) => {
                return data.pcid == selectedCategory.id
            })
            setSelectedPasses(passes)
        }

    }, [selectedCategory, refresh])









    const handleSubmit = (e) => {
        e.preventDefault();

        if (!eid || !name || !includedZones.length > 0) {
            if (!name) {
                twit('info', 'Please provide the pass category.')
            }

            if (!includedZones.length > 0) {
                twit('info', 'Please select atleast one access zone.')
            }


        } else {
            createEventPassCategory(setLoading).then((data) => {
                twit('success', data);
                setRefresh(!refresh);
                setName('');
                setIncludedZones([]);
                setPtemplate();
                setDescription('')
            }).catch((error) => {
                twit('info', error)
            })


        }



    };


    const handlePassUpdate = (e) => {
        e.preventDefault();

        if (!eid || !selectedZones.length > 0) {
            if (!selectedZones.length > 0) {
                twit('info', 'No access zone selected.')
            }

        } else {
            updateEventPassCategory(setLoading).then((data) => {
                twit('success', data)
                setRefresh(!refresh)
                setSelectedRzones([])
                setSelectedZones([])
                setUpdatePass(false);
                setPtemplate()
                setDescription('')
            }).catch((error) => {
                twit('info', error)
            })


        }



    };


    const handlePassSubmit = (e) => {
        e.preventDefault();

        if (!hname) {
            twit('info', 'Please provide the name.')
        } else {
            createPassHuman(setLoading).then((data) => {
                twit('success', data)
                setRefresh(!refresh)
                setHname('');
                setEmail('');
                setFaceImage('')
                setPassTitle('')
                setProfession('')
                setOtherTitle('')
                setSendPassOnMail(false)
            }).catch((error) => {
                twit('info', error)
            })


        }



    };

    const sendEventPassEmails = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            mails: emails,
            eid,
            pcid: pctid,
            admits
        };
        const Url = '/api/events/invite/email'
        try {
            setLoading(true);
            setSendingMails(true)
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });


            if (response.status === 201) {
                setLoading(false);
                setSendingMails(false)
                return Promise.resolve(response.data.message)

            }


        } catch (err) {
            setLoading(false);
            setSendingMails(false)
            console.log("Event creation Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while sending event pass emails.')

        }
    };

    const handlePassEmailSend = (e) => {
        e.preventDefault();


        if (emails.length <= 0 || admits < 1) {

            if (emails.length < 1) {
                twit('info', `Please provide atleast one email`);
            } else {
                twit('info', `Number of admits should be atleast 1`);
            }


        } else {

            sendEventPassEmails(setLoading).then((data) => {
                setShowEmailsInput(false)
                twit('success', data)
                setEmails([])
                setRefresh(!refresh)
            }).catch((error) => {
                twit('info', error)
            })

        }



    };

    const handlePassSubmitUpdate = (e) => {
        e.preventDefault();


        updatePassHuman(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            setHname('');
            setEmail('');
            setUpdatePassInfo(false)
            setFaceImage('')
            setPassTitle('')
            setOtherTitle('')
            setProfession('')
        }).catch((error) => {
            twit('info', error)
        })






    };

    const disableALLaccess = () => {
        const containsdisabledZones = zones.filter((data) => {
            return !(data.isactive)
        })

        if (containsdisabledZones.length > 0) {
            return true
        } else {
            return false
        }
    }


    const PassColors = [{ name: 'Yellow', code: '#d8e309' }, { name: 'Blue', code: '#036780' }, { name: 'Green', code: '#1a7d04' }, { name: 'Red', code: '#b50505' }, { name: 'Black', code: 'Black' }, { name: 'Orange', code: 'orange' }, { name: 'Purple', code: 'purple' }]
    const getColorName = (code) => {
        const color = PassColors.filter((clr) => {
            return clr.code == code
        })

        return color[0]?.name

    }


    //pass pdf logic
    const getPassCategoryZones = (data) => {
        const passZones = data?.PassCategoryZones?.filter((pcd) => {
            return pcd.pstate
        }).map((pcd) => {
            return { name: pcd.EventZone.name, desc: pcd.EventZone.desc }
        })

        return passZones
    }




    useEffect(() => {

        if (imageOnly && (activeOnly || inactiveOnly)) {
            simulateLoading(setLoadingSim, 0.2)
            if (activeOnly) {
                setPassDownloadData(eventPasses.filter((data) => {
                    return ((data.pstatus) && (data.face))
                }))
            } else if (inactiveOnly) {
                setPassDownloadData(eventPasses.filter((data) => {
                    return (!(data.pstatus) && (data.face))
                }))
            }

        } else if (!imageOnly && (activeOnly || inactiveOnly)) {
            simulateLoading(setLoadingSim, 0.2)


            if (activeOnly) {
                setPassDownloadData(eventPasses.filter((data) => {
                    return (data.pstatus)
                }))
            } else if (inactiveOnly) {
                setPassDownloadData(eventPasses.filter((data) => {
                    return (!data.pstatus)
                }))
            }
        } else if (imageOnly && !activeOnly && !inactiveOnly) {
            simulateLoading(setLoadingSim, 0.2)
            setPassDownloadData(eventPasses.filter((data) => {
                return (data.face)
            }))
        } else {
            simulateLoading(setLoadingSim, 0.2)
            setPassDownloadData(eventPasses)
        }

    }, [imageOnly, eventPasses, activeOnly, inactiveOnly]);





    //passes pdf pagination logic

    const postsPerPagePassPdf = 40
    const indexOfLastPostPassPdf = currentPagePassPdf * postsPerPagePassPdf;
    const indexOfFirstPostPassPdf = indexOfLastPostPassPdf - postsPerPagePassPdf;
    // const paginatedPassessForPdf = eventPasses?.sort((a, b) => a.name.localeCompare(b.name))?.filter((ps) => {
    //     return (ps.pcid == categoryRowData?.id) && (ps.pstatus)
    // })?.slice(indexOfFirstPostPassPdf, indexOfLastPostPassPdf)

    const paginatedPassessForPdf = passDownloadData?.sort((a, b) => a.name.localeCompare(b.name))?.filter((ps) => {
        return (ps.pcid == categoryRowData?.id)
    })?.slice(indexOfFirstPostPassPdf, indexOfLastPostPassPdf)


    const passChunksPaginated = eventPasses ? splitArrayIntoChunks(paginatedPassessForPdf, 4) : []


    const paginatePassPdf = (number) => {
        setCurrentPagePassPdf(number);
        simulateLoading(setLoadingSim, 0.2);

    }

    const passData = eventPasses?.filter((ps) => {
        return (ps.pcid == categoryRowData?.id) && (ps.pstatus)
    })
    const passChunks = eventPasses ? splitArrayIntoChunks(passData, 4) : []



    //pagination logic
    const postsPerPage = 5


    

    //for pass humans
    const indexOfLastPostH = currentPageH * postsPerPage;
    const indexOfFirstPostH = indexOfLastPostH - postsPerPage;
    const paginatedPassHuman = eventPasses?.filter((pass) =>
        pass?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      ).sort((a, b) => a.name.localeCompare(b.name))?.filter((data) => {
        return data.pcid == selectedCategory.id
    })?.slice(indexOfFirstPostH, indexOfLastPostH)
    const paginateH = (number) => {
        setCurrentPageH(number);

    }

    //for pass categories
    const indexOfLastPostC = currentPageC * postsPerPage;
    const indexOfFirstPostC = indexOfLastPostC - postsPerPage;
    const paginatedPassCategories = eventCategories?.slice(indexOfFirstPostC, indexOfLastPostC)
    const paginateC = (number) => {
        setCurrentPageC(number);

    }


    const otherPassTitleId = pTitles?.filter((title) => {
        return title?.name == 'Other'
    })[0]?.id



    const importPasses = async (passids) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            passIdentityIds: passids,
            pcid: selectedCategory.id,
            shareOnEmail: passids?.length > 1 ? false : identityShareOnMail
        };

        const Url = '/api/events/passidentities/transfer'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Passes Imported successfully.')
            }


        } catch (err) {
            setLoading(false);
            console.log("Passes Import Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while importing passes.')

        }
    };

    const handlePassImportation = (selectedPassData) => {
        const passIds = selectedPassData.map((data) => {
            return data.id
        })

        if (passIds?.length > 0) {
            importPasses(passIds).then((data) => {
                twit('success', data)
                setRefresh(!refresh)
                setShowPassImport(false)
                setIdentityShareOnMail(false)
            }).catch((error) => {
                setShowPassImport(false)
                twit('info', error, 4)
            })
        } else {
            twit('info', 'Please select atleast one pass identity to import.', 4)
        }

    }



    if (loading || loadingSponsors || loadingSim) {
        return (<Loader message={sendingMails ? `Sent ${numberSent} of ${numberToSend}` : null} />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>
                {showPassDownload && <Modal open={true} title={''} onCancel={() => {

                    setShowPassDownload(false)
                }}
                    footer={[


                    ]}



                    className="allmodals passA4"

                >


                    <div style={{ fontSize: '12px', margin: '2px', minHeight: '20vh' }} >
                        {passData?.length > 0 && (
                            <div className='mt-2 ' style={{ display: 'flex', flexDirection: 'row', width: '100%', textAlign: 'start', justifyContent: 'start', alignItems: 'center', }}>
                                <Button className='mx-3 mt-2' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" onClick={() => {

                                    downloadPDF('page', () => { }, `${categoryRowData?.name}-passes`)


                                }} >
                                    <GrIcon.GrDownload className='mx-1' style={{ width: '15px', height: '15px' }} /> | Download
                                </Button>

                                <Form.Check

                                    type="checkbox"
                                    label={'Show Active Only'}
                                    name={`active-only`}
                                    checked={activeOnly && !inactiveOnly}
                                    onChange={() => {
                                        if (!activeOnly) {
                                            setInactiveOnly(false)
                                        }
                                        setActiveOnly(!activeOnly)
                                    }}
                                    className="my-0 mx-1 input-box-events form-control-sm"
                                />

                                <Form.Check

                                    type="checkbox"
                                    label={'Show with Image Only'}
                                    name={`image-only`}
                                    checked={imageOnly}
                                    onChange={() => {
                                        setImageOnly(!imageOnly)
                                    }}
                                    className="my-0 mx-1 input-box-events form-control-sm"
                                />


                                <Form.Check

                                    type="checkbox"
                                    label={'Show Inactive Only'}
                                    name={`inactive-only`}
                                    checked={!activeOnly && inactiveOnly}
                                    onChange={() => {
                                        if (!inactiveOnly) {
                                            setActiveOnly(false)
                                        }
                                        setInactiveOnly(!inactiveOnly)

                                    }}
                                    className="my-0 mx-1 input-box-events form-control-sm"
                                />

                            </div>
                        )}
                        <div id='page'>
                            {passChunksPaginated.map((chunk, index) => (
                                <PassPage dataArray={chunk} passZones={getPassCategoryZones(categoryRowData)} pageNumber={index} eventDetails={eventInfo} allZones={zones} sponsors={sponsors} template={ptemplate} />
                            )

                            )}
                        </div>



                        {passData?.length <= 0 && (
                            <div className='mt-2' style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', width: '100%', textAlign: 'center' }}>
                                <p className='my-5'>No passes created.</p>
                            </div>
                        )}

                        <Pagination
                            postsPerPage={postsPerPagePassPdf}
                            totalPosts={passData?.length}
                            paginate={paginatePassPdf}
                            currentPage={currentPagePassPdf}
                        />

                    </div>


                </Modal>}


                {showPassImport && <Modal open={true} title={''} onCancel={() => {

                    setShowPassImport(false)
                }}
                    footer={[


                    ]}



                    className="allmodals"

                >


                    <UserSelectionComponent onToggleEmail={setIdentityShareOnMail} onSubmit={handlePassImportation} users={passIdentities} />


                </Modal>}

                {showPasses && <Modal open={true} title={'Pass Management'} onCancel={() => {

                    setShowPasses(false)
                    setShowPassImport(false)
                    setCurrentPageH(1);

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} className='table-responsive'>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{selectedCategory.name}</p>
                        </div>

                        <Form onSubmit={handlePassSubmit}>

                            <Card>
                                <Card.Body >
                                    <Row>
                                        <Col className='my-1' md={12}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Title</p>
                                                </div>
                                                <Form.Select

                                                    name="passTitle"
                                                    onChange={(e) => {
                                                        setPassTitle(e.target.value)
                                                    }}
                                                    className='form-selector'>
                                                    <option className='input-box' key={1} value={''}>{passTitle ? getCurrentPassTitle() : 'Select Pass Title'}</option>

                                                    {pTitles?.map((data) => {
                                                        return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                                                    })}


                                                </Form.Select>
                                            </div>

                                        </Col>
                                        {(passTitle == otherPassTitleId) ?

                                            <Col className='my-1' md={12}>
                                                <Form.Group controlId="name">
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                        <div className='mx-2 FormLabels'>
                                                            <p style={{ margin: 0 }}>Other Title</p>
                                                        </div>

                                                        <Form.Control
                                                            required
                                                            className=' input-box-events form-control-sm'
                                                            type="text"
                                                            placeholder="Enter Other Title"
                                                            value={otherTitle}
                                                            onChange={(e) => setOtherTitle(e.target.value)}

                                                        />
                                                    </div>

                                                </Form.Group>
                                            </Col>
                                            :
                                            <></>

                                        }
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="name">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Name<span style={{ color: "red" }}> *</span></p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter name"
                                                        value={hname}
                                                        onChange={(e) => setHname(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>

                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="email">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Email</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="email"
                                                        placeholder="Enter email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>

                                        <Col className='my-1' md={12}>

                                            <Form.Group controlId="designation">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Designation</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        type="text"
                                                        placeholder="Enter Designation"
                                                        value={profession}
                                                        onChange={(e) => setProfession(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>

                                        <Col className='my-1' md={12}>
                                            <Form.Group className='my-2' controlId="faceImage">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Visual</p>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                                                        <div>
                                                            <div className='my-1 ' style={{ width: '100%', }} >


                                                                <ImagePreview selectedImage={faceImage} size={'600 * 600'} containerHeight='300px' containerWidth='300px' containerHeightMobile='200px' containerWidthMobile='200px' placeholderMessage={'This will appear On The Generated Pass.'} />

                                                            </div>
                                                        </div>
                                                        <div style={{ width: '100%' }}>
                                                            <Form.Control

                                                                accept='.jpeg,.jpg,.png'
                                                                name="faceImage"
                                                                onChange={(e) => {

                                                                    //setFaceImage(e.target.files[0]);
                                                                    setCropperImage(URL.createObjectURL(e.target.files[0]));
                                                                    setShowCropper(true)

                                                                }}
                                                                className=' input-box-events form-control-sm'

                                                                type="file" />
                                                        </div>
                                                    </div>

                                                </div>

                                            </Form.Group>

                                        </Col>

                                        <Col className='my-1' md={12} >

                                            <Form.Group className='my-2' controlId="eventType">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                    <div className='d-flex flex-row   w-100'>



                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Share Pass On Email"
                                                            name="sharePassOnMail"
                                                            checked={sendPassOnMail}
                                                            onChange={(e) => {
                                                                setSendPassOnMail(!sendPassOnMail)
                                                            }}

                                                            className="my-0 mx-1 input-box-events form-control-sm"
                                                        />




                                                    </div>
                                                </div>


                                            </Form.Group>


                                            <div className='mt-2' style={{ width: '100%', textAlign: 'end' }}>
                                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" onClick={() => {
                                                    setShowPassImport(true)
                                                }} >
                                                    <FaFileImport className='mx-1' style={{ width: '15px', height: '15px' }} /> | Import Passes
                                                </Button>
                                            </div>


                                        </Col>

                                    </Row>



                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Add Pass
                                </Button>
                            </div>
                        </Form>

                        <Form.Control
                            className=' input-box-events form-control-sm my-2'
                            type="text"
                            placeholder="Search pass..."
                            value={searchTerm}
                            onChange={handleSearch}

                        />

                        {eventPasses.filter((data) => {
                            return data.pcid == selectedCategory.id
                        }).length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th> Status</th>
                                        <th> State</th>
                                        <th> Sticker No. </th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedPassHuman.filter((data) => {
                                        return data.pcid == selectedCategory.id
                                    })?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td >{((currentPageH * 5) - postsPerPage) + (index + 1)}</td>
                                            <td >{data.name ? data.name : data.email}</td>
                                            <td >{!(data.pstatus) ? <ImCross style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                            <td >{data.name ? <p style={{ color: 'green', fontSize: '12px', margin: '0px' }}>REGISTERED</p> : <p style={{ margin: '0px', color: 'red', fontSize: '12px' }}>PENDING</p>}</td>
                                            <td >{data.indexNumber ? threeDigits(Number(data.indexNumber)) : 'N/A'}</td>
                                            <td>
                                                {data.pstatus ? (
                                                    <HiIcons.HiUserRemove className='mx-1' title='Revoke Pass' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                        revokePass(setLoading, data.id).then((data) => {
                                                            setRefresh(!refresh)
                                                            twit('success', data)
                                                        }).catch((error) => {
                                                            twit('info', error)
                                                        })
                                                    }} />
                                                ) : (
                                                    <HiIcons.HiUserAdd className='mx-1' title='Activate Pass' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                        revokePass(setLoading, data.id).then((data) => {
                                                            setRefresh(!refresh)
                                                            twit('success', data)
                                                        }).catch((error) => {
                                                            twit('info', error)
                                                        })
                                                    }} />
                                                )}

                                                <GrIcon.GrUploadOption className='mx-1' title='Update Pass Details' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                    setHname('')
                                                    setHumanRowData(data)
                                                    const titleData = pTitles?.filter((tt) => {
                                                        return tt.id == data.titleid
                                                    })
                                                    setProfession(data.profession)
                                                    setInitialTitle(titleData[0]?.name)
                                                    setPassTitle(data.titleid)
                                                    setUpdatePassInfo(true)


                                                }} />



                                               
                                                <a href={`${apiBaseUrl}/api/events/pass/download/singlesticker/${data.pcid}/${data.id}`} download={`${data.name ? data.name : ''}`}><GrIcon.GrDownloadOption className='mx-1' title='Download Pass' style={{ height: "15px", width: "15px" }} />Sticker</a>
                                                

                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No passes added.</p>

                        }
                        {eventPasses?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={eventPasses.filter((data) => {
                                    return data.pcid == selectedCategory.id
                                })?.filter((ticket) =>
                                    ticket?.name?.toLowerCase().includes(searchTerm.toLowerCase())
                                  ).length}
                                paginate={paginateH}
                                currentPage={currentPageH}
                            />
                        )}


                    </div>


                </Modal>}

                {updatePass && <Modal open={true} title={'Pass Management'} onCancel={() => {

                    setUpdatePass(false)
                    setSelectedRzones([])
                    setPtemplate()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        <Form onSubmit={handlePassUpdate}>

                            <Card>
                                <Card.Body >
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="name">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Pass Category</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        //maxLength={10}
                                                        type="text"
                                                        placeholder="Enter Pass Category"
                                                        value={(name != '') ? name : categoryRowData.name}
                                                        onChange={(e) => {

                                                            if (e.target.value !== '') {
                                                                setName(e.target.value)
                                                            } else {
                                                                setName(null);
                                                            }
                                                        }}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="desc">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Description</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        //maxLength={10}
                                                        type="text"
                                                        placeholder="Enter Description"
                                                        value={(description != '') ? description : categoryRowData.description}
                                                        onChange={(e) => {

                                                            if (e.target.value !== '') {
                                                                setDescription(e.target.value)
                                                            } else {
                                                                setDescription(null);
                                                            }
                                                        }}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="passColor">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Pass Color</p>
                                                    </div>

                                                    <Form.Select

                                                        name="eventPassColor"
                                                        onChange={(e) => {
                                                            setPcolor(e.target.value)
                                                        }}
                                                        className='form-selector'>

                                                        <option className='input-box' key={1} value={pcolor}>{pcolor ? getColorName(pcolor) : 'Select Pass Color'}</option>
                                                        {PassColors.map((data) => {
                                                            return <option className='input-box' key={data.id} value={data.code}>{data.name}</option>
                                                        })}


                                                    </Form.Select>
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="passTemplate">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Pass Template</p>
                                                    </div>

                                                    <Form.Select

                                                        name="passtemplate"
                                                        onChange={(e) => {
                                                            setPtemplate(e.target.value)
                                                        }}
                                                        className='form-selector'>

                                                        <option className='input-box' key={1} value={ptemplate}>{ptemplate ? ptemplate : 'Select Pass Template'}</option>
                                                        {[{ id: 1, name: 'Template A' }, { id: 2, name: 'Template B' }].map((data) => {
                                                            return <option className='input-box' key={data.id} value={data.code}>{data.name}</option>
                                                        })}


                                                    </Form.Select>
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="access zones">
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0, }}>Access Zones</p>
                                                    </div>
                                                    <div>
                                                        <div className='d-flex flex-row   w-100'>

                                                            <Form.Check
                                                                disabled={disableALLaccess()}
                                                                type="checkbox"
                                                                label={'All Access'}
                                                                name="includedZones"
                                                                checked={selectedZones.length == zones.length}
                                                                onChange={(e) => {
                                                                    if (includedZones.length == zones.length) {
                                                                        setSelectedZones([])
                                                                        setSelectedRzones(zones.map((zd) => {
                                                                            return zd.zid
                                                                        }))
                                                                    } else {
                                                                        setSelectedZones(zones.map((zd) => {
                                                                            return zd.zid
                                                                        }))
                                                                        setSelectedRzones([])
                                                                    }
                                                                }}
                                                                className="my-0 mx-1  input-box-events form-control-sm"
                                                            />

                                                            {zones.slice(0, 2).map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={selectedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (selectedZones.includes(data.zid)) {
                                                                            setSelectedZones(selectedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                            if (!selectedRzones.includes(data.zid)) {
                                                                                selectedRzones.push(data.zid)
                                                                            }
                                                                        } else {
                                                                            setSelectedZones([...selectedZones, data.zid])
                                                                            if (selectedRzones.includes(data.zid)) {
                                                                                setSelectedRzones(selectedRzones.filter((zones) => {
                                                                                    return zones != data.zid
                                                                                }))
                                                                            }
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>
                                                        <div className='d-flex flex-row   w-100'>



                                                            {zones.slice(2, 5).map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={selectedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (selectedZones.includes(data.zid)) {
                                                                            setSelectedZones(selectedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                            if (!selectedRzones.includes(data.zid)) {
                                                                                selectedRzones.push(data.zid)
                                                                            }
                                                                        } else {
                                                                            setSelectedZones([...selectedZones, data.zid])
                                                                            if (selectedRzones.includes(data.zid)) {
                                                                                setSelectedRzones(selectedRzones.filter((zones) => {
                                                                                    return zones != data.zid
                                                                                }))
                                                                            }
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>
                                                        <div className='d-flex flex-row   w-100'>



                                                            {zones.slice(5).map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={selectedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (selectedZones.includes(data.zid)) {
                                                                            setSelectedZones(selectedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                            if (!selectedRzones.includes(data.zid)) {
                                                                                selectedRzones.push(data.zid)
                                                                            }
                                                                        } else {
                                                                            setSelectedZones([...selectedZones, data.zid])
                                                                            if (selectedRzones.includes(data.zid)) {
                                                                                setSelectedRzones(selectedRzones.filter((zones) => {
                                                                                    return zones != data.zid
                                                                                }))
                                                                            }
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>
                                                    </div>



                                                </div>

                                            </Form.Group>
                                        </Col>
                                    </Row>



                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GrIcon.GrUploadOption className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Pass Category
                                </Button>
                            </div>
                        </Form>

                    </div>


                </Modal>}

                {updatePassInfo && <Modal open={true} title={'Pass Management'} onCancel={() => {
                    setProfession('')
                    setUpdatePassInfo(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >



                        <Form onSubmit={handlePassSubmitUpdate}>

                            <Card>
                                <Card.Body >

                                    {/* <Card.Img variant="top" style={{ maxHeight: '350px' }} src={'https://st4.depositphotos.com/6903990/27898/i/450/depositphotos_278981062-stock-photo-beautiful-young-woman-clean-fresh.jpg'} /> */}

                                    <Row>

                                        <Col className='my-1' md={4}>
                                            <Card.Img variant="top" style={{ maxHeight: '350px' }} alt='face' src={imageLoaded ? `${humanRowData.face}` : visualPlacer} onLoad={() => {

                                            }} onError={() => {
                                                setImageLoaded(false)
                                            }} />
                                        </Col>


                                        <Col className='my-1' md={8}>
                                            <Row>
                                                <Col className='my-1' md={12}>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                        <div className='mx-2 FormLabels'>
                                                            <p style={{ margin: 0 }}>Title<span style={{ color: "red" }}> *</span></p>
                                                        </div>
                                                        <Form.Select

                                                            name="passTitle"
                                                            onChange={(e) => {
                                                                setPassTitle(e.target.value)
                                                            }}
                                                            className='form-selector'>
                                                            <option className='input-box' key={1} value={''}>{initialTitle ? initialTitle : 'Select Pass Title'}</option>

                                                            {pTitles?.map((data) => {
                                                                return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                                                            })}


                                                        </Form.Select>
                                                    </div>

                                                </Col>
                                                {((passTitle == otherPassTitleId) || (humanRowData.titleid == otherPassTitleId)) ?

                                                    <Col className='my-1' md={12}>
                                                        <Form.Group controlId="name">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Other Title</p>
                                                                </div>

                                                                <Form.Control
                                                                    required
                                                                    className=' input-box-events form-control-sm'
                                                                    type="text"
                                                                    placeholder="Enter Other Title"
                                                                    value={(otherTitle != '' ? otherTitle : humanRowData?.otherTitle)}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            setOtherTitle(e.target.value)
                                                                        } else {
                                                                            setOtherTitle(null)
                                                                        }


                                                                    }}

                                                                />
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                    :
                                                    <></>

                                                }
                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="name">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>



                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Name<span style={{ color: "red" }}> *</span></p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="text"
                                                                placeholder="Enter name"
                                                                value={(hname != '' ? hname : humanRowData?.name)}
                                                                onChange={(e) => {

                                                                    if (e.target.value !== '') {
                                                                        setHname(e.target.value)
                                                                    } else {
                                                                        setHname(null)
                                                                    }
                                                                }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>

                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="email">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>



                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Email</p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="email"
                                                                placeholder="Enter email"
                                                                value={(email != '' ? email : humanRowData?.email)}
                                                                onChange={(e) => {

                                                                    if (e.target.value !== '') {
                                                                        setEmail(e.target.value)
                                                                    } else {
                                                                        setEmail(null)
                                                                    }
                                                                }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>

                                                    <Form.Group controlId="designation">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Designation</p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="text"
                                                                placeholder="Enter Designation"
                                                                value={profession}
                                                                onChange={(e) => setProfession(e.target.value)}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="faceImage">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Visual</p>
                                                            </div>
                                                            <Form.Control

                                                                accept='.jpeg,.jpg,.png'
                                                                name="faceImage"
                                                                onChange={(e) => {

                                                                    //setFaceImage(e.target.files[0]);
                                                                    setCropperImage(URL.createObjectURL(e.target.files[0]));
                                                                    setShowCropper(true)

                                                                }}
                                                                className=' input-box-events form-control-sm'

                                                                type="file" />
                                                        </div>

                                                    </Form.Group>

                                                </Col>
                                            </Row>
                                        </Col>




                                    </Row>



                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GrIcon.GrUploadOption className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Pass
                                </Button>
                            </div>
                        </Form>



                    </div>


                </Modal>}

                {categoryRowData && showEmailsInput && <Modal open={true} title={<div className='mb-2' style={{ width: '100%', textAlign: 'start' }}>

                    Pass Sharing

                </div>} onCancel={() => {
                    setCategoryRowData()
                    setEmails([])
                    setPctid('')
                    setShowEmailsInput(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div>
                        <Form onSubmit={handlePassEmailSend}>
                            <div className='my-1' style={{ textAlign: 'start' }}>
                                {/* <Clipboard style={{ borderRadius: '5px', border: '0' }} option-text={() => (`${clientEngineBaseUrl}/events/selfreg/${rowdata.eid}`)} onSuccess={() => { twit('success', 'URL copied.') }}>
                                    <HiIcons.HiClipboardCopy style={{ height: "15px", width: "15px" }} />   Copy event URL
                                </Clipboard> */}
                                <Form.Group controlId="admits">
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                        <div className='mx-2 FormLabels'>
                                            <p style={{ margin: 0 }}>Admits</p>
                                        </div>

                                        <Form.Control
                                            className=' input-box-events form-control-sm'
                                            max={10}
                                            min={1}
                                            type="number"
                                            placeholder="Enter number of people pass admit"
                                            value={admits}
                                            onChange={(e) => setAdmits(e.target.value)}

                                        />
                                    </div>

                                </Form.Group>

                            </div>
                            <ReactMultiEmail
                                placeholder='Input your email'
                                emails={emails}
                                style={{ fontSize: '12px' }}
                                onChange={(_emails) => {
                                    setEmails(_emails);
                                }}
                                autoFocus={true}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div style={{ fontSize: '10px' }} data-tag key={index}>
                                            <div data-tag-item>{email}</div>
                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                                ×
                                            </span>
                                        </div>
                                    );
                                }}
                            />

                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black', fontSize: '10px' }} variant="primary" type="primary" htmlType='submit'>
                                    <AiIcons.AiOutlineSend style={{ height: "15px", width: "15px" }} />| Send Mails
                                </Button>
                            </div>

                        </Form>





                    </div>


                </Modal>}

                {showCropper && cropperImage && <Modal open={true} title={`Pass Image Formating (600 * 600)`} onCancel={() => {
                    setShowCropper(false)
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >

                        <Card>
                            <Card.Body >
                                <Row>
                                    <ImageCropper image={cropperImage} setImage={setCropperImage} setOpen={setShowCropper} setCropResultFile={setFaceImage} windowWidth={'300px'} windowHeight={'300px'} aspectRatio={(600 / 600)} />
                                </Row>
                            </Card.Body>
                        </Card>

                    </div>


                </Modal>}

                <Row>
                    <Col>
                        <p style={{ fontWeight: 'bold' }}>Pass Management </p>
                        <Form onSubmit={handleSubmit}>

                            <Card>
                                <Card.Body >
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="name">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Pass Category</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        // maxLength={10}
                                                        type="text"
                                                        placeholder="Enter Pass Category"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="desc">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Description</p>
                                                    </div>

                                                    <Form.Control
                                                        className=' input-box-events form-control-sm'
                                                        // maxLength={10}
                                                        type="text"
                                                        placeholder="Enter Description"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}

                                                    />
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="passColor">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Pass Color</p>
                                                    </div>

                                                    <Form.Select

                                                        name="eventPassColor"
                                                        onChange={(e) => {
                                                            setPcolor(e.target.value)
                                                        }}
                                                        className='form-selector'>

                                                        <option className='input-box' key={1} value={'Black'}>Select Pass Color</option>
                                                        {PassColors.map((data) => {
                                                            return <option className='input-box' key={data.id} value={data.code}>{data.name}</option>
                                                        })}


                                                    </Form.Select>
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={4}>
                                            <Form.Group controlId="passtemplate">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Pass Template</p>
                                                    </div>

                                                    <Form.Select

                                                        name="passtemplate"
                                                        onChange={(e) => {
                                                            setPtemplate(e.target.value)
                                                        }}
                                                        className='form-selector'>

                                                        <option className='input-box' key={1} value={'Black'}>Select Pass Template</option>
                                                        {[{ id: 1, name: 'Template A' }, { id: 2, name: 'Template B' }].map((data) => {
                                                            return <option className='input-box' key={data.id} value={data.name}>{data.name}</option>
                                                        })}


                                                    </Form.Select>
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col className='my-1' md={12}>
                                            <Form.Group controlId="access-zones">
                                                <div className='pass-access-zones' style={{ maxWidth: '100%' }} >
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Access Zones</p>
                                                    </div>
                                                    <div style={{ maxWidth: '100%', }} >
                                                        <div className='scrollable-checkbox-div '>

                                                            <Form.Check
                                                                disabled={disableALLaccess()}
                                                                type="checkbox"
                                                                label={'All Access'}
                                                                name="includedZones"
                                                                checked={includedZones.length == zones.length}
                                                                onChange={(e) => {
                                                                    if (includedZones.length == zones.length) {
                                                                        setIncludedZones([])
                                                                    } else {
                                                                        setIncludedZones(zones.map((zd) => {
                                                                            return zd.zid
                                                                        }))
                                                                    }
                                                                }}
                                                                className="my-0 mx-1  input-box-events form-control-sm"
                                                            />

                                                            {zones.map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={includedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (includedZones.includes(data.zid)) {
                                                                            setIncludedZones(includedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                        } else {
                                                                            setIncludedZones([...includedZones, data.zid])
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div>
                                                        {/* <div className='d-flex flex-row   w-100'>



                                                            {zones.slice(2).map((data) => (

                                                                <Form.Check
                                                                    disabled={!(data.isactive)}
                                                                    type="checkbox"
                                                                    label={data.name}
                                                                    name="includedZones"
                                                                    checked={includedZones.includes(data.zid)}
                                                                    onChange={(e) => {
                                                                        if (includedZones.includes(data.zid)) {
                                                                            setIncludedZones(includedZones.filter((zones) => {
                                                                                return zones != data.zid
                                                                            }))
                                                                        } else {
                                                                            setIncludedZones([...includedZones, data.zid])
                                                                        }
                                                                    }}
                                                                    className="my-0 mx-1  input-box-events form-control-sm"
                                                                />


                                                            ))}


                                                        </div> */}
                                                    </div>



                                                </div>

                                            </Form.Group>
                                        </Col>
                                    </Row>



                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Add Pass Category
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <p style={{ fontWeight: 'bold' }}> Event Pass Categories </p>
                    <div className='table-responsive'>
                        {eventCategories.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>Awarded</th>
                                        <th>Active</th>
                                        <th>Inactive</th>
                                        <th>Access Rights</th>
                                        <th>Color</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedPassCategories?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPageC * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.name}</td>
                                            <td>{data.description}</td>
                                            <td>{(eventInfo?.endtime < getNairobiTime()) ? <FcExpired title='Expired' style={{ height: "15px", width: "15px", color: 'red' }} /> : !(data.isactive) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                            <td>{eventPasses?.filter((ps) => {
                                                return ps.pcid == data.id
                                            })?.length}</td>

                                            <td>{eventPasses?.filter((ps) => {
                                                return (ps.pcid == data.id) && (ps.pstatus)
                                            })?.length}</td>

                                            <td>{eventPasses?.filter((ps) => {
                                                return (ps.pcid == data.id) && !(ps.pstatus)
                                            })?.length}</td>

                                            <td>{getPassCategeoryRights(data)}</td>
                                            <td><ColorCube colorCode={data.pcolor} /></td>

                                            <td>


                                                <MdIcons.MdPreview className='mx-1' title='View Passes' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                    setSelectedCategory(data)
                                                    setShowPasses(true)

                                                }} />


                                                <GrIcon.GrUploadOption className='mx-1' title='Update Pass Category' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                    setName('')
                                                    const passZones = data?.PassCategoryZones?.filter((pcd) => {
                                                        return pcd.pstate
                                                    }).map((pcd) => {
                                                        return pcd.EventZone.zid
                                                    })
                                                    setSelectedZones(passZones)
                                                    setCategoryRowData(data)
                                                    setPcolor(data?.pcolor)
                                                    setPtemplate(data.passtemplate)
                                                    setUpdatePass(true)


                                                }} />

                                                {!(eventInfo?.endtime < getNairobiTime()) && data.isactive && (eventInfo?.prive) && (
                                                    <BsIcons.BsFillShareFill className='mx-1' title='Send Invitations' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                        setCategoryRowData(data)
                                                        setShowEmailsInput(true)
                                                        setPctid(data.id)
                                                    }} />
                                                )}

                                                {!(eventInfo?.endtime < getNairobiTime()) && (
                                                    <>
                                                        {data.isactive ? (
                                                            <IoIosRemoveCircleOutline className='mx-1' title='Deactivate Pass Category' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                                revokeActivateCategory(setLoading, data.id).then((data) => {
                                                                    setRefresh(!refresh)
                                                                    twit('success', data)
                                                                }).catch((error) => {
                                                                    twit('info', error)
                                                                })
                                                            }} />
                                                        ) : (
                                                            <IoIosAddCircleOutline className='mx-1' title='Activate Pass Category' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                                revokeActivateCategory(setLoading, data.id).then((data) => {
                                                                    setRefresh(!refresh)
                                                                    twit('success', data)
                                                                }).catch((error) => {
                                                                    twit('info', error)
                                                                })
                                                            }} />
                                                        )}
                                                    </>
                                                )}
                                                <GrIcon.GrDownload className='mx-1' title='Download Category Passes' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                    setCategoryRowData(data)
                                                    setPtemplate(data.passtemplate)
                                                    setShowPassDownload(true)

                                                }} />

                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No pass categories added.</p>

                        }
                        {eventCategories?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={eventCategories?.length}
                                paginate={paginateC}
                                currentPage={currentPageC}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }
};

export default PassCategoryForm;
